import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getURLParameter } from "../utility/utility";
import { getAccessToken } from "../utility/ApiHelper";

const LoginCallback = () => {
  const navigate = useHistory();
  const code = getURLParameter("code");

  const callActionItem = async (inputCode) => {
    let accessToken = await getAccessToken(inputCode);
    if (accessToken?.success && accessToken?.data?.access_token) {
      sessionStorage.setItem("accessToken", accessToken?.data?.access_token);
      sessionStorage.setItem("refreshToken", accessToken?.data?.refresh_token);
      sessionStorage.setItem("idToken", accessToken?.data?.id_token);
      sessionStorage.removeItem("code_verifier");
      navigate.push(`/home`);
    }
  };

  useEffect(() => {
    callActionItem(code);
  }, [code]);

  return <></>;
};
export default LoginCallback;
