import React from "react";
import {
  Grid,
  Typography,
  Stack,
  Button,
  Box,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { CUSTOMER_LOOKUP } from "../../routes";

const SuccessMessage = ({
  successMessage = "",
  firstName = "",
  mobileNumber = "",
  resetRegistration,
}) => {
  const history = useHistory();

  const onAddAnotherCustomer = () => {
    if (resetRegistration) {
      resetRegistration(false);
    }
  };

  return (
    <>
      <Card className="success">
        <CardContent>
          <Grid container mt={5} direction={"row"} justifyContent="center">
            <Grid item sm={4}>
              <Alert severity="success">
                <Typography variant="h6" component="h6">
                  {successMessage}
                </Typography>
              </Alert>

              <Box margin={1}>
                <span>Customer Name : </span> {firstName}
              </Box>
              <Box margin={1}>
                <span>Mobile Number :</span> {mobileNumber}
              </Box>
              <Stack
                direction={"column"}
                spacing={1}
                className="buttons"
                mt={3}
              >
                <Button
                  className="button-primary"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={() => history.push(CUSTOMER_LOOKUP)}
                >
                  Look up Customer
                </Button>
                <Button
                  className="button-secondary"
                  variant="outlined"
                  fullWidth
                  style={{ width: "100%" }}
                  onClick={() => onAddAnotherCustomer()}
                >
                  Add another Customer
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SuccessMessage;
