import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Grid,
  Card,
  CardContent,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar
} from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import DOMPurify from 'dompurify';
import moment from "moment";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import jwt_decode from "jwt-decode";

import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../header/header";
import { countries } from "../utility/country";
import {
  getHeaders,
  validatePhoneNumber,
  getEmptyOnNull,
} from "../utility/utility";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
};

theme.typography.h5 = {
  fontSize: "1.5rem",
};

const initialState = {
  countryCode: process.env.REACT_APP_DEFAULT_COUNTRY,
  phoneNumber: "",
  dob: null,
  submitStatusCustLookup: null,
  submitMsgCustLookup: "",
  customerDetails: [],
};

const labels = {
  countryCode: "Country Code",
  phoneNumber: "Mobile Number",
};

const requiredFieldsObj = {
  countryCode: "+1",
  phoneNumber: "",
};

function createData(name, address, phone) {
  return { name, address, phone };
}

const errorText = "Please fill this field";
const toastMsg = "Some Error Occurred, Please try again";

function CustomerLookup(props) {
  const history = useHistory();
  const [registerObj, setRegisterObj] = useState({ ...initialState });
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [helperText, setHelperText] = useState(errorText);
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [toastMessage, setToastMessage] = useState(toastMsg);
  const [errorToastStatus, setErrorToastStatus] = useState(false);
  const { state, pathname } = useLocation();

  useEffect(() => {
    // setRegisterObj({ ...registerObj, ["submitStatus"]: "" });
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const token = jwt_decode(accessToken);
      const id = token.sub;
      setUsername(id);
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }, []);

  useEffect(() => {
    if (state?.fromPath === "customerRegistration") {
      setRegisterObj({
        ...registerObj,
        customerDetails: state.customerDetails,
        dob : state.pageInfo.dob,
        phoneNumber : state.pageInfo.phoneNumber,
        countryCode: state.pageInfo.countryCode
      });
    }
  }, [state?.fromPath]);


  const onClickRedirectToNewCustRegistration = () => {
          history.push({
            pathname: "/customerRegistration",
            state: {
              fromPath: "lookupWithNewCustomer",
                countryCode : registerObj?.countryCode || "US",
                phoneNumber: registerObj?.phoneNumber || null,
                dob: registerObj?.dob || null,    
            },
          });
  };

  const onClickRedirectToCustRegistrationForEdit = (i) => {
    let submittedObj = {};
    submittedObj.customerUmn =
      registerObj.customerDetails[i]?.customer?.customerUmn;
    submittedObj.requestInitiatedBy = username;

    axios
      .post(
        `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL}/v1/pos/customer/lookup`,
        submittedObj,
        getHeaders(
          sessionStorage.getItem("accessToken") !== null
            ? sessionStorage.getItem("accessToken")
            : ""
        )
      )
      .then((response) => {
        if (response.status === 200 && response.data.noOfCustomers > 0) {
          const customerLookupResults = response.data?.customerDetails[0];
          history.push({
            pathname: "/customerRegistration",
            state: {
              customerLookupResults: customerLookupResults,
              customerDetails: registerObj.customerDetails,
              fromPath: "lookup",
              customerPos: i,
              pageInfo: {
                countryCode: registerObj.countryCode,
                phoneNumber: registerObj.phoneNumber,
                dob: registerObj.dob,
              },
            },
          });
        } else {
          console.log("error occurred during customer lookup");
        }
      })
      .catch((error) => {
        console.log("error occurred during customer lookup");
      });
  };

  const onSearch = () => {
    setLoading(true);

    let isSearch = true;
    let missingField = null;
    for (const key in requiredFieldsObj) {
      const val = registerObj[key];
      if (val === "error" || !val) {
        missingField = key;
        isSearch = false;
        break;
      }
    }

    if (!isSearch) {
      setErrorToastStatus(true);
        setToastMessage(
          "Please populate the field" + " " + labels[missingField]
        );
      setLoading(false);
      return;
    }

    // do api call here
    let submittedObj = {};
    submittedObj.isdCode = countries.filter(
      (country) => country.code === registerObj.countryCode
    )?.[0]?.isdCode.replace("+", "");
    submittedObj.phoneNumber = registerObj.phoneNumber;
    submittedObj.dob = registerObj.dob
      ? moment(registerObj.dob).format("YYYY-MM-DD")
      : null;
    submittedObj.requestInitiatedBy = username;

    axios
      .post(
        `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL}/v1/pos/customer/search`,
        submittedObj,
        getHeaders(
          sessionStorage.getItem("accessToken") !== null
            ? sessionStorage.getItem("accessToken")
            : ""
        )
      )
      .then((response) => {
        if (response.status === 200 && response.data.noOfCustomers > 0) {
          registerObj.submitStatusCustLookup = "OK";
          registerObj.submitMsgCustLookup = "Successful";
          // registerObj.customerDetails = response.data.customerDetails;
          setRegisterObj({
            ...registerObj,
            customerDetails: response.data.customerDetails,
          });

          setLoading(false);
        } else {
          registerObj.submitStatusCustLookup = "NO-DATA";
          registerObj.submitMsgCustLookup = "Successful";
          setLoading(false);
        }
      })
      .catch((error) => {
        registerObj.submitStatusCustLookup = "ERROR";
        registerObj.submitMsgCustLookup =
          "Some error occurred, please try again.";
        setLoading(false);
      });
  };

  const getCountryCodes = () => {
    return countries.map((country) => {
      return (
        <MenuItem key={country.code} value={country.code}>
          {country.name} ({country.isdCode})
        </MenuItem>
      );
    });
  };

  const onChangeHandler = (e, fieldName) => {
    let value;
    if (fieldName === "dob") {
      value = e!== null ?DOMPurify.sanitize(e.$d): null;
    } else {
      value = DOMPurify.sanitize(e.target.value);
    }
    setRegisterObj({ ...registerObj, [fieldName]: value });
  };

  const onBlurHandler = (e, fieldName) => {
    let value;
    if (fieldName === "dob") {
      value = DOMPurify.sanitize(e);
    } else {
      value =
        typeof e.target.value === "string"
          ? DOMPurify.sanitize(e.target.value.trim())
          : DOMPurify.sanitize(e.target.value);
    }

    if (!value) {
      setRegisterObj({ ...registerObj, [fieldName]: "error" });
    } else if (fieldName === "phoneNumber") {
      const isValid = validatePhoneNumber(registerObj.countryCode, value);
      if (!isValid) {
        setRegisterObj({ ...registerObj, [fieldName]: "error" });
        setPhoneErrorText("Please enter valid mobile number");
      } else {
        setRegisterObj({ ...registerObj, [fieldName]: value });
        setPhoneErrorText("");
      }
    } else setRegisterObj({ ...registerObj, [fieldName]: value });
  };

  return (
    <>
      {<Header history={history} heading="" />}

      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        spacing={4}
        margin={2}
        className="lookup-content"
      >
        <Grid item md={4} sm={10}>
          <Card className="search-card">
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={errorToastStatus}
            autoHideDuration={6000}
            onClose={() => setErrorToastStatus(false)}
          >
            <Alert
              onClose={() => setErrorToastStatus(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {toastMessage}
            </Alert>
          </Snackbar>
            <CardContent>
              <ThemeProvider theme={theme}>
                <Typography variant="h5">Customer Lookup</Typography>
              </ThemeProvider>
              <Grid mt={1} container direction={"row"} spacing={3}>
                <Grid item sm={4}>
                  <FormControl fullWidth variant="standard" className="age">
                    <InputLabel id="demo-simple-select-standard-label">
                      Country Code
                    </InputLabel>
                    <Select
                      value={
                        registerObj["countryCode"] !== "error"
                          ? registerObj["countryCode"]
                          : ""
                      }
                      error={registerObj["countryCode"] === "error"}
                      onChange={(e) => onChangeHandler(e, "countryCode")}
                      onBlur={(e) => onBlurHandler(e, "countryCode")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {getCountryCodes()}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={8}>
                  <TextField
                    required
                    label="Mobile Number"
                    variant="standard"
                    className="mobile"
                    fullWidth
                    value={
                      registerObj["phoneNumber"] !== "error"
                        ? registerObj["phoneNumber"]
                        : ""
                    }
                    error={registerObj["phoneNumber"] === "error"}
                    helperText={
                      registerObj["phoneNumber"] === "error"
                        ? phoneErrorText || helperText
                        : ""
                    }
                    onChange={(e) => onChangeHandler(e, "phoneNumber")}
                    onBlur={(e) => onBlurHandler(e, "phoneNumber")}
                    InputProps={{
                      endAdornment: (
                        <PhoneIphoneIcon></PhoneIphoneIcon>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid mt={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth(Optional)"
                    inputFormat="MM-DD-YYYY"
                    disableFuture={true}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" fullWidth />
                    )}
                    value={registerObj["dob"]}
                    onChange={(e) => onChangeHandler(e, "dob")}
                  />
                </LocalizationProvider>
              </Grid>
              <Stack
                direction={"column"}
                alignItems="flex-end"
                spacing={3}
                className="buttons"
                mt={3}
              >
                <Button
                  className="button-primary"
                  variant="contained"
                  onClick={() => onSearch()}
                >
                  {loading ? "Loading ..." : "Search"}
                </Button>
                <Button
                  className="button-secondary"
                  variant="outlined"
                  onClick={() => history.push("/home")}
                >
                  Back
                </Button>
              </Stack>
              <Stack>
                {registerObj.submitStatusCustLookup === "NO-DATA" && (
                  <Alert
                    icon={<CheckCircleIcon fontSize="inherit" />}
                    severity="success"
                  >
                    <AlertTitle>No data found</AlertTitle>
                    {(registerObj.customerDetails = "")}
                    <Button
                      className="button-primary"
                      variant="contained"
                      onClick={() =>
                        onClickRedirectToNewCustRegistration()
                      }
                    >
                      Click here to register new customer
                    </Button>
                  </Alert>
                )}
                {registerObj.submitStatusCustLookup === "ERROR" && (
                  <Alert
                    icon={<ErrorIcon fontSize="inherit" />}
                    severity="error"
                  >
                    <AlertTitle>Error</AlertTitle>
                    {registerObj.submitMsgCustLookup}
                  </Alert>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {registerObj.customerDetails?.length > 0 && (
          <Grid item md={7} sm={10}>
            <Card className="result-card">
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" mb={2}>
                    Search Results
                  </Typography>
                </ThemeProvider>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ bgcolor: '#FFDD00'}}>Name</TableCell>
                        <TableCell sx={{ bgcolor: '#FFDD00'}}>Address</TableCell>
                        <TableCell sx={{ bgcolor: '#FFDD00'}}>Phone</TableCell>
                        <TableCell sx={{ bgcolor: '#FFDD00'}}>Edit details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {registerObj.customerDetails.map((record, i) => (
                        <TableRow
                          key={i}
                        >
                          <TableCell component="th" scope="row">
                            {getEmptyOnNull(record?.customer?.name?.firstName) +
                              " " +
                              getEmptyOnNull(
                                record?.customer?.name?.middleName
                              ) + " " +
                              getEmptyOnNull(
                                record?.customer?.name?.lastNamePaternal
                              ) + " "+
                              getEmptyOnNull(
                                record?.customer?.name?.lastNameMaternal
                              ) +
                              " " +
                              getEmptyOnNull(record?.customer?.name?.lastName)}
                          </TableCell>
                          <TableCell>
                            {getEmptyOnNull(
                              record?.address[0]?.streetAddress1
                            ) +
                              " " +
                              getEmptyOnNull(
                                record?.address[0]?.streetAddress2
                              ) +
                              " " +
                              getEmptyOnNull(record?.address[0]?.city) +
                              " " +
                              getEmptyOnNull(
                                record?.address[0]?.stateProvince
                              ) +
                              " " +
                              getEmptyOnNull(record?.address[0]?.zipPostalCode)}
                          </TableCell>
                          <TableCell>
                            {getEmptyOnNull(record?.phone[0]?.phoneNumber)}
                          </TableCell>
                          <TableCell>
                            <EditIcon
                              onClick={() =>
                                onClickRedirectToCustRegistrationForEdit(i)
                              }
                            ></EditIcon>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default CustomerLookup;
