import jwt_decode from "jwt-decode";
import { PhoneNumberUtil } from "google-libphonenumber";


export const getHeaders = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": `POSCSOBD` + Math.floor(Math.random() * 100000),
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_CUS_OBD_API_KEY,
    },
  };
};

export const getHeadersForOcr = (token) => {
  return {
    headers: {
      "content-type": "image/png",
      "x-wu-externalRefId": `POSCSOBD` + Math.floor(Math.random() * 100000),
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_CUS_OBD_API_KEY,
    },
  };
};

export const getHeadersForToken = () => {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
};

export const validatePhoneNumber1 = (value) => {
  return /^\d{10}$/.test(value);
};

export const validatePhoneNumber = (countryCode, phoneNumber) => {
  let valid = false;
  const enableValidation =
    process.env.REACT_APP_ENABLE_COUNTRY_BASED_PHONE_NUMBER_VALIDATION;
  if (enableValidation == "true") {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(phoneNumber, countryCode),
        countryCode
      );
    } catch (e) {
      valid = false;
    }
  } else {
    return /^[0-9\b]+$/.test(phoneNumber);
  }

  return valid;
};

export const isValidToken = (token) => {
  if (token === undefined || token === null) {
    return false;
  }
  try {
    let decodedToken = jwt_decode(token);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (
      decodedToken === null ||
      decodedToken.exp * 1000 < currentDate.getTime()
    ) {
      console.log("Token expired.");
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const validateEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const getEmptyOnNull = (value) => {
  return value || "";
};

export const getURLParameter = (paramName) => {
  const urlParts = decomposeUrl(window.location.href);
  return urlParts.queryParams[paramName];
};

const decomposeUrl = (url) => {
  if (!url) {
    return {};
  }

  const a = document.createElement("a");
  a.href = url;

  return {
    host: a.host,
    pathname: a.pathname,
    search: a.search,
    queryParams: parseQueryParams(a.search),
    hash: a.hash,
  };
};

const parseQueryParams = (searchStr) => {
  const str = searchStr.replace(/^\?/, "");
  const params = str.split("&");

  const returnVal = {};

  params.forEach((param) => {
    const paramSplit = param.split("=");
    returnVal[paramSplit[0]] = paramSplit[1];
  });

  return returnVal;
};
