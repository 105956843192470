import React from "react";
import {
  Grid,
  Typography,
  Stack,
  Button,
  Box,
  TextField,
  CardContent,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Countdown from "react-countdown";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OtpValidator = ({
  onCloseModal,
  verifierInfo = "",
  onOtpSubmit,
  onOtpResend,
  phoneOtpError
}) => {
  const [submittedOtp, setSubmittedOtp] = React.useState("");
  const [resendEnabled, setResendEnabled] = React.useState(false);
  const [timerExpireTime, setTimerExpireTime] = React.useState(
    Date.now() + 90000
  );

  const onChangeHandler = (e, fieldName) => {
    let value = e.target.value.trim();
    if (value) {
      setSubmittedOtp(value);
    } else {
      setSubmittedOtp("error");
    }
  };

  const onBlurHandler = (e) => {
    let value = e.target.value.trim();
    if (value) {
      setSubmittedOtp(value);
    } else {
      setSubmittedOtp("error");
    }
  };

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setResendEnabled(true);
      return "";
    } else {
      // Render a countdown
      return (
        <span>
          Resend in {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const onResendClick = () => {
    setResendEnabled(false);
    setTimerExpireTime(Date.now() + 90000);
    onOtpResend();
  };

  const onSubmitClick = () => {
    if (submittedOtp && submittedOtp !== "error") {
      onOtpSubmit(submittedOtp);
    } else {
      setSubmittedOtp("error");
    }
  };

  return (
    <>
      <Modal open={true} onClose={onCloseModal}>
        <Box sx={modalStyle}>
          <CardContent>
            <Grid container mt={5} direction={"row"} justifyContent="center">
              <Grid item sm={12}>
                <Typography variant="h6" component="h6" align="center">
                  Please enter the verification code sent to {verifierInfo}
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={5}
                  className="buttons"
                  mt={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    required
                    className="field"
                    label="Enter verification code"
                    variant="standard"
                    // fullWidth
                    sx={{ input: { textAlign: "center" } }}
                    value={submittedOtp !== "error" ? submittedOtp : ""}
                    error={submittedOtp === "error" || !!phoneOtpError}
                    helperText={
                      submittedOtp === "error"
                        ? "Please enter verification code to continue" 
                        :
                      !!phoneOtpError ? phoneOtpError
                        : ""
                    }
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onBlurHandler(e)}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={5} direction={"row"} spacing={1} justifyContent="flex-start" className="buttons">
              <Grid item sm={6} xs={12} >
              <Button
                    className="button-primary"
                    variant="contained"
                    style={{ width: "100%" }}
                    onClick={() => onSubmitClick()}
                    fullWidth
                  >
                    Submit
                  </Button>
              </Grid>
              <Grid item sm={6} xs={12} >
              <Button
                    className="button-secondary"
                    variant="outlined"
                    fullWidth
                    disabled={!resendEnabled}
                    style={{ width: "100%" }}
                    onClick={() => onResendClick()}
                  >
                    {resendEnabled ? (
                      "Resend"
                    ) : (
                      <Countdown date={timerExpireTime} renderer={renderer} />
                    )}
                  </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Modal>
    </>
  );
};

export default OtpValidator;
