import axios, { post } from "axios";
import { getHeadersForOcr, getHeaders } from "../utility/utility";

//function to get the access token from  ping server
export const getAccessToken = async (code) => {
  const code_verifier = sessionStorage.getItem("code_verifier");
  const res = await fetch(
    process.env.REACT_APP_PING_TOKEN_ISSUER + `/as/token.oauth2`,
    {
      // Adding method type
      method: "POST",
      // Adding headers to the request
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_CUS_OBD_PING_CLIENT_ID,
        //code_verifier: process.env.REACT_APP_CUS_OBD_PING_CODE_VERIFIER,
        code_verifier: code_verifier,
        code: code,
        redirect_uri: process.env.REACT_APP_CUS_OBD_PING_REDIRECT_URI,
      }),
    }
  );
  const data = await res.json();
  if (data && res.status == 200) {
    return { success: true, data: data };
  } else {
    return { success: false, error: data };
  }
};

export const revokeAccessToken = async () => {
  const token = sessionStorage.getItem("refreshToken");
  const response = await fetch(
    process.env.REACT_APP_PING_TOKEN_ISSUER + `/as/revoke_token.oauth2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_CUS_OBD_PING_CLIENT_ID,
        token: token,
        token_type_hint: "refresh_token",
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to refresh access token");
  } else {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
  }
};


export const refreshAccessToken = async (refreshToken) => {
  const response = await fetch(
    process.env.REACT_APP_PING_TOKEN_ISSUER + `/as/token.oauth2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_CUS_OBD_PING_CLIENT_ID,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to refresh access token");
  }
  const data = await response.json();
  if (data && response.status == 200) {
    return { success: true, data: data };
  } else {
    return { success: false, data: data };
  }
};

export const uploadImageToBucket = async (
  fileType,
  ocrObj,
  fileName,
  imageFile
) => {
  const formData = new FormData();
  formData.append(fileType, imageFile);
  const res = await axios
    .put(
      `${process.env.REACT_APP_CUS_OBD_S3_BUCKET_UPLOAD_PATH}/${ocrObj.country}/${ocrObj.idType}/${ocrObj.idSubType}/${fileName}`,
      imageFile,
      getHeadersForOcr(
        sessionStorage.getItem("accessToken") !== null
          ? sessionStorage.getItem("accessToken")
          : ""
      )
    )
    .catch(() => {
      console.log("error : upload failed");
      return false;
    });

  if (res.status == 200) {
    return true;
  } else {
    console.log("Image upload failed");
    return false;
  }
};

export const idVerify = async (
  ocrObj,
  frontImageFileName,
  backImageFileName,
  promoterId
) => {
  const submittedObj = {
    imagePath: {
      front: `${ocrObj?.country}/${ocrObj?.idType}/${ocrObj?.idSubType}/${frontImageFileName}`,
      back:  backImageFileName !== null &&  backImageFileName !== undefined ?  `${ocrObj?.country}/${ocrObj?.idType}/${ocrObj?.idSubType}/${backImageFileName}` : null
    },
    idType: ocrObj?.idType || null,
    idSubType: ocrObj?.idSubType || null,
    issueCountry: ocrObj?.country || null,
    requestInitiatedBy: promoterId,
  };
  const res = await axios
    .post(
      `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL_FOR_OCR}/v1/pos/id/verify`,
      submittedObj,
      getHeaders(
        sessionStorage.getItem("accessToken") !== null
          ? sessionStorage.getItem("accessToken")
          : ""
      )
    )
    .catch(() => {
      console.log("id verification failed");
      return false;
    });

  if (res.status == 200) {
    console.log("id verification completed");
    return { accountId: res.data.accountId, workflowId: res.data.workflowId, dmsDocRefNumber: res.data.dmsDocRefNumber};
  } else {
    console.log("id verification failed");
    return false;
  }
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const idExtract = async (accountId, workflowId, promoterId) => {
  const submittedObj = {
    accountId: accountId,
    workflowId: workflowId,
    requestInitiatedBy: promoterId,
  };


  let resData;
  const idExtractPoll = async () => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL_FOR_OCR}/v1/pos/id/extract`,
        submittedObj,
        getHeaders(
          sessionStorage.getItem("accessToken") !== null
            ? sessionStorage.getItem("accessToken")
            : ""
        )
      )
      .catch(() => {
        console.log("id extract failed");
        return false;
      });

    if (res.status == 200 && res.data.status == "PROCESSED") {
      resData = { data: res.data?.data };
      return true;
    } 
     else if (res.status == 200 && (res.data.status == "ACQUIRED" ||  res.data.status == "INITIATED")) {
      console.log("retrying .....");
      await delay(3000);
      await idExtractPoll();
    } else {
      console.log("id Extraction failed");
      return false;
    }
  };


    await idExtractPoll();
    return resData;

  
};
