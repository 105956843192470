import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./components/home/homePage";
import LoginCallback from "./components/login/LoginCallback";
import LoginPage from "./components/login/LoginPage";
import PromoterRegistration from "./components/promoter-registration/promoterRegistration";
import CustomerRegistration from "./components/customer-registration/customerRegistration";
import CustomerLookup from "./components/customer-lookup/customerLookup";
import SuccessMessage from "./components/success/success";
import ProtectedRoute from "./ProtectedRoute";
import {
  HOME_PAGE,
  LOGIN,
  LOGIN_CALLBACK,
  CUSTOMER_LOOKUP,
  CUSTOMER_REGISTRATION,
  PROMOTER_REGISTRATION,
  REGISTRATION_SUCCESS,
} from "./routes";
import { refreshAccessToken } from "../src/components/utility/ApiHelper";

import "./App.css";

const App = () => {

  useEffect(() => {
    setInterval(() => {
        renewAccessToken(sessionStorage.getItem("refreshToken"));
    }, 1000 * 60 * 15);
  }, []);

  const renewAccessToken = async (refreshToken) => {
    let accessToken = await refreshAccessToken(refreshToken);
    if (accessToken?.success && accessToken?.data?.access_token) {
      sessionStorage.setItem("accessToken", accessToken?.data?.access_token);
      sessionStorage.setItem("refreshToken", accessToken?.data?.refresh_token);
    }
  };


  return (
    <div>
      <div className="App">
        {/* <Header
          history={history}
          heading=""
        /> */}
        <Router>
          <Route path={LOGIN_CALLBACK} exact component={LoginCallback} />
          <Route path={LOGIN} exact component={LoginPage} />
          <ProtectedRoute path={HOME_PAGE} exact component={HomePage} />
          <ProtectedRoute
            path={PROMOTER_REGISTRATION}
            exact
            component={PromoterRegistration}
          />
          <ProtectedRoute
            path={CUSTOMER_LOOKUP}
            exact
            component={CustomerLookup}
          />
          <ProtectedRoute
            path={CUSTOMER_REGISTRATION}
            exact
            component={CustomerRegistration}
          />
          <ProtectedRoute
            path={REGISTRATION_SUCCESS}
            exact
            component={SuccessMessage}
          />
        </Router>
      </div>
    </div>
  );
};

export default App;
