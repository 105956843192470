import React, { useEffect } from "react";
import pkceChallenge from "pkce-challenge";
import { generateChallenge } from "pkce-challenge";

function LoginPage(props) {
  const client_id = process.env.REACT_APP_CUS_OBD_PING_CLIENT_ID;
  const redirect_uri = process.env.REACT_APP_CUS_OBD_PING_REDIRECT_URI;
  const ping_sign_in_url = process.env.REACT_APP_PING_SIGN_IN_URL;

  const challenge = pkceChallenge(128);
  const code_verifier = challenge.code_verifier;
  sessionStorage.setItem("code_verifier", code_verifier);

  const scope = [
    "profile openid email post:pos.customer.registration post:pos.promoter.registration post:pos.customer.lookup post:pos.customer.search post:pos.otpauth.generate post:pos.otpauth.verify post:pos.id.verify post:pos.id.extract post:pos.id.verify.status",
  ];
  useEffect(() => {
    try {
      const code_challenge = generateChallenge(code_verifier);
      let authUrl = `${ping_sign_in_url}?response_type=code&redirect_uri=${redirect_uri}&client_id=${client_id}&scope=${scope}&code_challenge=${code_challenge}&code_challenge_method=S256`;
      window.location.replace(authUrl);
    } catch (error) {
      console.error(error);
    }
  }, [code_verifier]);

  return <></>;
}

export default LoginPage;
