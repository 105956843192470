export const languageMap = 
  {
     US : [
      {
        language: "Spanish",
        code: "ES",
      },
    ],
    CA : [
      {
        language: "Canadian French",
        code: "FR",
      },
    ],
  }
