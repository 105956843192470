import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, FormHelperText,  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem, 
  CircularProgress,
  Backdrop} from "@mui/material";

import Modal from "@mui/material/Modal";
import "./styles.css";
import CapturePhoto from "./CapturePhoto";
import { uploadImageToBucket, idVerify, idExtract } from "../utility/ApiHelper";
import { countriesMap } from "../utility/countryMap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  minHeight: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const rObj = {
  country: process.env.REACT_APP_DEFAULT_COUNTRY_ISO3,
  idType: "ID",
  idSubType: "DRIVING_LICENSE",
};

const getCountries = () => {
  return countriesMap.map((country) => {
    return (
      <MenuItem key={country.code} value={country.code}>
        {country.name}
      </MenuItem>
    );
  });
};

const backImageSupportedIds = ['passport'];

function urlToFile(url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export default function BasicModal({
  promoterId,
  openModal,
  setOpenModal,
  setDetailsFromPhoto,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [ocrObj, setOcrObj] = useState(rObj);
  const helperText = "Please fill this field";

  const frontPlayer = React.useRef();
  const backPlayer = React.useRef();

  const {
    initializeMedia: frontInitializeMedia,
    capturePicture: frontCapturePicture,
    imageDataURL: frontImageDataURL,
  } = CapturePhoto(frontPlayer);

  const {
    initializeMedia: backInitializeMedia,
    capturePicture: backCapturePicture,
    imageDataURL: backImageDataURL,
  } = CapturePhoto(backPlayer);

  const [frontImage, setFrontImage] = React.useState();
  const [frontImageFile, setFrontImageFile] = React.useState();
  const [backImage, setBackImage] = React.useState();
  const [backImageFile, setBackImageFile] = React.useState();
  const [frontOrBack, setFrontOrBack] = React.useState();
  const [frontImageFileName, setFrontImageFileName] = React.useState();
  const [backImageFileName, setBackImageFileName] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  


  useEffect(() => {
    if (frontImageDataURL && frontOrBack === "front") {
      setFrontImage(frontImageDataURL);

      setFrontImageFileName(`front-${Math.floor(new Date().getTime())}.png`);
      urlToFile(frontImageDataURL, frontImageFileName).then(function (file) {
        setFrontImageFile(file);
      });
    }
  }, [frontImageDataURL, frontOrBack]);

  useEffect(() => {
    if (backImageDataURL && frontOrBack === "back") {
      setBackImage(backImageDataURL);
      setBackImageFileName(`back-${Math.floor(new Date().getTime())}.png`);
      urlToFile(backImageDataURL, backImageFileName).then(function (file) {
        setBackImageFile(file);
      });
    }
  }, [backImageDataURL, frontOrBack]);

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
    resetFiles();
    setOcrObj({ ...rObj });
    setErrorMessage("");
  };

  const resetFiles = () => {
    setFrontImage("");
    setFrontImageFile(null);
    setBackImage("");
    setBackImageFile(null);
    setFrontImageFileName("");
    setBackImageFileName(null);
  }

  React.useEffect(() => {
    if (openModal) {
      const accessToken = sessionStorage.getItem("idToken");
    try {
      const token = jwt_decode(accessToken);
      const id = token.sub;
      setPromoterId(id);
      // valid token format
    } catch (error) {
      // invalid token format
    }
      handleOpen();
      
    }
  }, [openModal]);

  useEffect(() => {
    return () => {
      setOcrObj({ ...rObj });
      resetFiles();
      setErrorMessage("");
    };
  }, []);

  
  const isDisabled = () => {
    
    if (!frontImageFile) {
      return true;
    } 
    else if(frontImageFile) {
      return !backImageSupportedIds.includes(ocrObj.idSubType);
    }
    
  };

  const setZipCode = (zipCode, countryName) => {
    
    if (countryName ==="USA" && zipCode.includes("-")) {
      return zipCode.slice(0,5);
    } 
    else 
    return zipCode;
  }

  const getCountry = (countryName) => {
    
    if (countryName ==="USA") {
      return "US";
    } if (countryName ==="CAN") {
      return "CA";
    }
    else 
      return countryName;  
  };


  const submitHandler = async () => {

    setLoading(true);

     let uploadStatus = false;

    const frontUploadStatus = await uploadImageToBucket(
      "front",
      ocrObj,
      frontImageFileName,
      frontImageFile
    );
    console.log("frontUploadStatus", frontUploadStatus);

    if (frontUploadStatus === true) {
      uploadStatus = true;
      if (backImageFile != null) {
        const backUploadStatus = await uploadImageToBucket(
          "back",
          ocrObj,
          backImageFileName,
          backImageFile
        );
        console.log("backUploadStatus", backUploadStatus);

        if (backUploadStatus === true) {
          console.log("both images are uploaded")    
        } else {
          setLoading(false);
          setErrorMessage("Image upload failed. Please try again...")
          uploadStatus = false;
        }
      }
    }else {
      console.log("front image upload failed");
      setLoading(false);
      setErrorMessage("Image upload failed. Please try again...")
    }
    if(uploadStatus){

      const idVerifyResponse = await idVerify(
        ocrObj,
        frontImageFileName,
        backImageFileName,
        promoterId
      );

      if(idVerifyResponse) {
        console.log('idVerifyResponse', idVerifyResponse);
        
          const idExtractResponse = await idExtract(idVerifyResponse.accountId, idVerifyResponse.workflowId, promoterId);
          console.log('idExtractResponse', idExtractResponse);
          if(idExtractResponse && idExtractResponse?.data?.firstName !== null){
          setOpen(false);
          setOpenModal(false);
          setLoading(false);
          resetFiles();
          setDetailsFromPhoto({ firstName: idExtractResponse?.data?.firstName,
            lastName: idExtractResponse?.data?.lastName,
            dob:idExtractResponse?.data?.dateOfBirth,
            streetAddress1:idExtractResponse?.data?.address?.line1,
            streetAddress2:idExtractResponse?.data?.address?.line2,
            city:idExtractResponse?.data?.address?.city,
            zipCode:setZipCode(idExtractResponse?.data?.address?.postalCode,idExtractResponse?.data?.address?.country),
            state:idExtractResponse?.data?.address?.subdivision,
            country:getCountry(idExtractResponse?.data?.address?.country)});
          }
          else {

        console.log("ID Verification failed. Please try again....");
        setLoading(false);
        setErrorMessage("Unable to extract data. Please try again....");
        resetFiles();

          }
       
      } else {
        console.log("ID Verification failed. Please try again....");
        setLoading(false);
        setErrorMessage("ID Verification failed. Please try again....");
        resetFiles();
         
      }

    }
    else {
      console.log("upload failed");
      setLoading(false);
      resetFiles();
    }
  };

  const frontPlayerORImage = Boolean(frontImageDataURL) ? (
    <div style={{ marginBottom: "15px", height: "100%" }}>
      {frontImage && (
        <img src={frontImage} alt="cameraPic" style={{ width: "200px" }} />
      )}
    </div>
  ) : (
    <video
      ref={(reference) => {
        frontPlayer.current = reference;
      }}
      style={{ width: "200px", marginBottom: "10px" }}
      autoPlay
    ></video>
  );

  const backPlayerORImage = Boolean(backImageDataURL) ? (
    <div style={{ marginBottom: "15px" }}>
      {backImage && (
        <img src={backImage} alt="cameraPic" style={{ width: "200px" }} />
      )}
    </div>
  ) : (
    <video
      ref={(reference) => {
        backPlayer.current = reference;
      }}
      style={{ width: "200px", marginBottom: "10px" }}
      autoPlay
    ></video>
  );

  const onChangeHandler = (e, fieldName) => {
    resetFiles();
    const value = e.target.value;
    setOcrObj({ ...ocrObj, [fieldName]: value });
  };

  const onBlurHandler = (e, fieldName) => {
    resetFiles();
    const value =
      typeof e.target.value === "string"
        ? e.target.value.trim()
        : e.target.value;

    if (!value) setOcrObj({ ...ocrObj, [fieldName]: "error" });
    else setOcrObj({ ...ocrObj, [fieldName]: value });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
 
        <Box sx={style}>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
            mb={3}
          >
            <Grid item md={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Scan ID
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography id="modal-modal-title" variant="h6" component="h3" color="red">
                {errorMessage ? errorMessage : ''}
              </Typography>
            </Grid>
            <Grid item onClick={() => handleClose()} md={2}>
              <span
                style={{ color: "black", fontSize: "25px", cursor: "pointer", float: "right" }}
              >
                X
              </span>
            </Grid>
          </Grid>
          <Grid container direction={"row"} spacing={8} alignItems="center">
            <Grid item>
              <FormControl variant="standard" className="age">
                <InputLabel id="demo-simple-select-standard-label">
                  Country
                </InputLabel>
                <Select
                  style={{ width: 150 }}
                  required
                  value={ocrObj["country"] !== "error" ? ocrObj["country"] : ""}
                  error={ocrObj["country"] === "error"}
                  onChange={(e) => onChangeHandler(e, "country")}
                  onBlur={(e) => onBlurHandler(e, "country")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {getCountries()}
                </Select>
                {ocrObj["country"] === "error" ? (
                  <FormHelperText className="select-error">
                    {helperText}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" className="age">
                <InputLabel id="demo-simple-select-standard-label">
                  ID Type
                </InputLabel>
                <Select
                  style={{ width: 100 }}
                  required
                  value={ocrObj["idType"] !== "error" ? ocrObj["idType"] : ""}
                  error={ocrObj["idType"] === "error"}
                  onChange={(e) => onChangeHandler(e, "idType")}
                  onBlur={(e) => onBlurHandler(e, "idType")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="ID">ID</MenuItem>
                </Select>
                {ocrObj["idType"] === "error" ? (
                  <FormHelperText className="select-error">
                    {helperText}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" className="age">
                <InputLabel id="demo-simple-select-standard-label">
                  ID Sub Type
                </InputLabel>
                <Select
                  style={{ width: 200 }}
                  required
                  value={
                    ocrObj["idSubType"] !== "error" ? ocrObj["idSubType"] : ""
                  }
                  error={ocrObj["idSubType"] === "error"}
                  onChange={(e) => onChangeHandler(e, "idSubType")}
                  onBlur={(e) => onBlurHandler(e, "idSubType")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="passport">Passport</MenuItem>
                  <MenuItem value="DRIVING_LICENSE">DRIVING_LICENSE</MenuItem>
                </Select>
                {ocrObj["idSubType"] === "error" ? (
                  <FormHelperText className="select-error">
                    {helperText}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={1} mt={2}>
            <Grid item sm={6}>
              <div className="front-photo" style={{ height: "170px" }}>
                {" "}
                {frontPlayerORImage}
              </div>

              <Grid direction={"column"} container className="buttons">
                <Button
                  className="button-secondary"
                  variant="outlined"
                  size="medium"
                  style={{ marginBottom: "10px", width: "60%" }}
                  onClick={() => {
                    frontInitializeMedia();
                    setFrontOrBack("");
                    setFrontImage("");
                    setErrorMessage("");
                  }}
                >
                  Take Front Image
                </Button>

                <Button
                  className="button-primary"
                  variant="contained"
                  size="medium"
                  style={{ width: "60%" }}
                  onClick={() => {
                    frontCapturePicture();
                    setFrontOrBack("front");
                  }}
                >
                  Capture Front Image
                </Button>
              </Grid>
            </Grid>
            
            <Grid item sm={6}>
              <div className="back-photo" style={{ height: "170px" }}>
                {" "}
                {backPlayerORImage}
              </div>
              <Grid direction={"column"} container className="buttons">
                <Button
                  className="button-secondary"
                  variant="outlined"
                  size="medium"
                  style={{ marginBottom: "10px", width: "60%" }}
                  disabled={isDisabled()}
                  onClick={() => {
                    backInitializeMedia();
                    setFrontOrBack("");
                    setBackImage("");
                  }}
                >
                  Take Back Image
                </Button>

                <Button
                  className="button-primary"
                  variant="contained"
                  size="medium"
                  style={{ width: "60%" }}
                  disabled={isDisabled()}
                  onClick={() => {
                    backCapturePicture();
                    setFrontOrBack("back");
                  }}
                >
                  Capture Back Image
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container position={"fixed"} bottom={15} height={30} right={0}>
            <Grid
              item
              position={"absolute"}
              bottom={0}
              right={15}
              className="buttons"
            >
              <Button
                disabled={!(frontImage || backImage)}
                variant="contained"
                className="button-primary"
                size="medium"
                style={{ width: "auto" }}
                onClick={() => submitHandler()}
              >
                 Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
