import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isValidToken } from "../src/components/utility/utility";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        sessionStorage.getItem("accessToken") != null ? (
          isValidToken(sessionStorage.getItem("accessToken")) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default ProtectedRoute;
