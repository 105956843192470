import React, { Component } from "react";
import Logo from "../../Asset/logo.png";
import IdleTimer from "react-idle-timer";
import {
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AiOutlineLogout, AiOutlineHome } from "react-icons/ai";
import { IconContext } from "react-icons";
import jwt_decode from "jwt-decode";
import { revokeAccessToken } from "../utility/ApiHelper";

class Header extends Component {

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      userName: "",
      timeout: 1000 * 60 * 10,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      firedFirstTime: true,
      isLoggedIn: false,
      emailId: "",
    };
  }
  componentDidMount() {
    const idToken = sessionStorage.getItem("idToken");
    this.getUserName(idToken);
    const id = jwt_decode(idToken).sub;
    this.setState({ emailId: id?.toLowerCase() });
  }

  getUserName = (idToken) => {
    try {
      if (sessionStorage.userName === undefined) {
        const name = jwt_decode(idToken).name;

        sessionStorage.setItem("userName", name);
        sessionStorage.userName = name;
        this.setState({ userName: name });
      }
    } catch (error) {
     
    }
  };
  onTimeOut = () => {
    this.setState({ isTimedOut: true });
    console.log("calling logout");
    this.logout();
  };

  onActive = () => {
    console.log("on active");
    this.setState({ isTimedOut: false });
  };

  onIdle = () => {
    this.onTimeOut();
  };
  onHandleBack = () => {
    this.props.history.push("/home");
  };
  async login() {
    sessionStorage.userName = "";
    this.setState({ isLoggedIn: true });
  }

  async logout() {
    this.setState({ isLoggedIn: false });
    console.log('calling revoke token')
    revokeAccessToken();
    this.props.history.push("/");
  }

  

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          debounce={250}
          timeout={this.state.timeout}
          data-test="button-timeout"
        />
    
        <nav className="navbar navbar-dark bg-dark-custom">
          <div className="nav-logo">
            <img
              src={Logo}
              width="180"
              height="50"
              className="cursor-pointer img-fluid"
              alt="Home"
              onClick={this.onHandleBack}
              data-test="button-back"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <IconContext.Provider
              value={{ color: "#FDD017", size: "30px" }}
              style={{ cursor: "pointer" }}
            >
              <AiOutlineHome
                onClick={this.onHandleBack}
                data-test="button-home-icon"
              />
            </IconContext.Provider>
          </div>
          <div className="nav-env-name">
            <h4 className="heading-header">
              {process.env.REACT_APP_DB_VIEW_ENV}
            </h4>
          </div>

          <div className="nav-login-details">
            {true ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Tooltip id="tooltip-email">
                      <strong>{this.state.emailId}</strong>
                    </Tooltip>
                  }
                >
                  <label className="logged-in-user">
                    {sessionStorage.userName !== ""
                      ? sessionStorage.userName
                      : this.state.userName}
                  </label>
                </OverlayTrigger>
                {/* <div className="cursor-pointer"> */}
                <IconContext.Provider
                  value={{ color: "#FDD017", size: "30px" }}
                >
                  <AiOutlineLogout
                    onClick={this.logout}
                    data-test="button-logoff"
                  />
                </IconContext.Provider>
                {/* </div> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
